<template>
  <v-container class="mt-4" :fluid="$vuetify.breakpoint.mdOnly">
    <div class="text-h4">Fornecedores</div>
  </v-container>
</template>

<script>
export default {
  name: "RecordsSuppliers",
  metaInfo() {
    return {
      title: "Fornecedores",
    };
  },
};
</script>
